import React, { forwardRef, useEffect, useState } from 'react'
import {
	Flex,
	Menu,
	MenuButton,
	MenuList,
	chakra,
	Box,
	Heading,
	HStack,
	Link,
	useMultiStyleConfig,
} from '@chakra-ui/react'

import TabProvider, { useTabContext } from './context'
import { NotificationsRounded, NotificationsActiveRounded } from '../../../../atoms/Icon'
import {
	fetchNotifications,
	markNotificationAsRead,
	markNotificationAsSeen,
} from '../../../../../../api/notifications/actions'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import FactoryNotification from './templates/FactoryNotification'

/**
 * Header of the Notifications drawer
 * Returns a title and the filters to toggle between 'all' and 'unread' notifications.
 */
const NotificationsHeader = () => {
	const { activeTab, setActiveTab } = useTabContext()

	const styles = useMultiStyleConfig('Notifications')

	return (
		<Box sx={styles.header.container}>
			<Flex sx={styles.header.flex}>
				<Heading as="h3" fontSize="1.5rem">
					Notifications
				</Heading>
				<HStack spacing="0.5rem" sx={styles.header.stack}>
					<Link
						_hover={{ textDecoration: 'none' }}
						color={activeTab == 'all' ? 'var(--vidday-colors-link)' : '#B5BCC3'}
						onClick={() => setActiveTab('all')}>
						All
					</Link>
					<Link
						_hover={{ textDecoration: 'none' }}
						color={activeTab == 'unread' ? 'var(--vidday-colors-link)' : '#B5BCC3'}
						onClick={() => setActiveTab('unread')}>
						Unread
					</Link>
				</HStack>
			</Flex>
		</Box>
	)
}

/**
 * Main Component rendering a button that will render
 * a list of notifications upon being clicked.
 * */
const Notifications = forwardRef((props, ref) => {
	const dispatch = useDispatch()

	/** Retrieve notifications from our state manager */
	const notifications = useSelector((s) => s.notifications.data, shallowEqual)

	/** Retrieve active tab key */
	const { activeTab } = useTabContext()

	/** Store collection of notifications corresponding to the active tab */
	const [activeNotifications, setActiveNotifications] = useState([])

	/** We want to count unseen notifications based on full collection of notifications */
	const [count, setCount] = useState(0)

	/**
	 * Filter notifications
	 */
	useEffect(() => {
		/** Filter 'all' notifications */
		if (activeTab == 'all') {
			setActiveNotifications(notifications)
		} else if (activeTab == 'unread') {
			/** Filter only 'read' notifications */
			setActiveNotifications(notifications.filter((el) => el.read == false))
		}
		/** Count unread notifications */
		setCount(notifications?.filter((el) => el.seen == false).length)
	}, [activeTab, notifications])

	/**
	 * When opening the Notifications drawer,
	 * we need to mark all unseen notifications as 'seen'
	 */
	const handleSetNotificationsAsSeen = () => {
		notifications.forEach((el) => {
			if (!el.seen) {
				dispatch(markNotificationAsSeen(el.id))
			}
		})
	}

	/** Retrieve component styles */
	const styles = useMultiStyleConfig('Notifications', { variant: count > 0 ? 'shake' : null })

	return (
		<Menu placement="bottom-end" ref={ref}>
			<MenuButton
				sx={styles.menuButton}
				as={chakra.button}
				aria-label="Notifications"
				aria-haspopup={true}
				onClick={handleSetNotificationsAsSeen}>
				{count > 0 ? (
					<>
						<NotificationsActiveRounded sx={styles.menuButtonIcon} boxSize="1.75rem" color="link" />
						<chakra.span sx={styles.notificationCount}>{count > 1 ? `${count}+` : count}</chakra.span>
					</>
				) : (
					<NotificationsRounded sx={styles.menuButtonIcon} boxSize="1.75rem" color="link" />
				)}
			</MenuButton>
			<MenuList sx={styles.menuList}>
				<NotificationsHeader />

				<Box sx={styles.menuContentWrapper}>
					{activeNotifications?.map((el, i) => (
						<FactoryNotification data={el} key={i} />
					))}
				</Box>
			</MenuList>
		</Menu>
	)
})

/**
 * Main Notifications Component wrapped into a
 * Context API in order to manage current active tab.
 */
export default () => {
	return (
		<TabProvider active="all">
			<Notifications />
		</TabProvider>
	)
}
