import React from 'react'
import { useMultiStyleConfig } from '@chakra-ui/react'
import { HStack, Image, Box, Text, chakra } from '@chakra-ui/react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { icons } from '../config'
import { formatToDecimalAmount } from '../../../../../../../api/checkout/utils'

// adds additional dayjs APIs --> https://day.js.org/docs/en/plugin/relative-time
dayjs.extend(relativeTime)

/**
 * RSVP > NEW RSVP
 * New RSVP from a contributor to an event
 */
const Funds = ({ content }) => {
	const { createdAt, user, anonymous, amount } = content

	/** Retrieve styles */
	const styles = useMultiStyleConfig('Notification')

	/** Custom icon styles */
	const iconStyles = {
		...styles.icon,
		bg: 'rgba(75, 190, 255, 0.1)',
		borderColor: '#4BBEFF',
	}

	return (
		<HStack spacing="0.5rem">
			<Box sx={iconStyles}>
				<Image src={`/assets/images/icons/notifications/${icons['new-funds']}.png`} boxSize="31px" />
			</Box>
			<Box>
				<Text lineHeight="1.125rem" fontSize="0.875rem">
					{!anonymous ? (
						user?.profile?.firstName ? (
							<chakra.strong>
								{user.profile.firstName} {user.profile.lastName ? user.profile.lastName : ''}
							</chakra.strong>
						) : (
							'Someone'
						)
					) : (
						'Someone'
					)}{' '}
					contributed {formatToDecimalAmount(amount)} towards a gift card.
				</Text>
				<Text fontSize="0.75rem" color="#6C7A88">
					{dayjs(createdAt).fromNow()}
				</Text>
			</Box>
		</HStack>
	)
}

export default Funds
