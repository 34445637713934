import { useMultiStyleConfig } from '@chakra-ui/react'
import React from 'react'
import { getTypeFromMimeType } from '../utils'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { HStack, Image, Box, Text, chakra } from '@chakra-ui/react'
import { icons } from '../config'

// adds additional dayjs APIs --> https://day.js.org/docs/en/plugin/relative-time
dayjs.extend(relativeTime)

/**
 * PREVIEW READY
 * Alert user when preview render jobs are ready
 */
const PreviewReady = ({ content }) => {
	const { contributorId, mimeType, createdAt, contributor = null, event } = content

	/** Retrieve styles */
	const styles = useMultiStyleConfig('Notification')

	/** Retrive mimetype label */
	const mediaLabel = getTypeFromMimeType(mimeType)

	/** Custom icon styles */
	const iconStyles = {
		...styles.icon,
		bg: 'rgba(75, 190, 255, 0.1)',
		borderColor: '#4BBEFF',
	}

	return (
		<HStack spacing="0.5rem">
			<Box sx={iconStyles}>
				<Image src={`/assets/images/icons/notifications/${icons['eyes']}.png`} boxSize="31px" />
			</Box>
			<Box>
				<Text lineHeight="1.125rem" fontSize="0.875rem">
					<chakra.strong>Your preview is ready</chakra.strong> for you to review
				</Text>
				<Text fontSize="0.75rem" color="#6C7A88">
					{dayjs(createdAt).fromNow()}
				</Text>
			</Box>
		</HStack>
	)
}

export default PreviewReady
