import { useMultiStyleConfig } from '@chakra-ui/react'
import React from 'react'
import { getTypeFromMimeType } from '../utils'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { HStack, Image, Box, Text, chakra } from '@chakra-ui/react'

// adds additional dayjs APIs --> https://day.js.org/docs/en/plugin/relative-time
dayjs.extend(relativeTime)

/**
 * SUBMISSION
 * New published media by contributors
 */
const Submission = ({ content }) => {
	const { isThankYouVideo, mimeType, createdAt, contributor = null } = content

	/** Retrieve styles */
	const styles = useMultiStyleConfig('Notification')

	/** Retrive mimetype label */
	const mediaLabel = getTypeFromMimeType(mimeType)

	const name = (
		<>
			{contributor ? (
				<chakra.strong>
					{contributor.profile?.firstName ? contributor.profile.firstName : ''}{' '}
					{contributor.profile?.lastName ? contributor.profile.lastName : ''}
				</chakra.strong>
			) : (
				'Someone'
			)}
		</>
	)

	const text = (
		<>
			{isThankYouVideo ? (
				<>
					<chakra.strong>Thank you</chakra.strong> from {name}.
				</>
			) : (
				<>
					{name} submitted a{mediaLabel == 'audio message' && 'n'} {mediaLabel}.
				</>
			)}
		</>
	)

	return (
		<HStack spacing="0.5rem">
			<Image
				boxSize="3.375rem"
				objectFit="cover"
				borderRadius="full"
				sx={styles.image}
				src={content.signedThumbnailUrl}
				alt="New media"
			/>
			<Box>
				<Text lineHeight="1.125rem" fontSize="0.875rem">
					{text}
				</Text>
				<Text fontSize="0.75rem" color="#6C7A88">
					{dayjs(createdAt).fromNow()}
				</Text>
			</Box>
		</HStack>
	)
}
export default Submission
