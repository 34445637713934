import { useMultiStyleConfig } from '@chakra-ui/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { markNotificationAsRead } from '../../../../../../../api/notifications/actions'
import { MenuItem, Flex, Box } from '@chakra-ui/react'
import templates from '../templates'

const FactoryNotification = ({ data }) => {
	/** retrieve dispatcher */
	const dispatch = useDispatch()

	/** Retrieve general styles of the Notification */
	const styles = useMultiStyleConfig('Notification')

	/** Decompose main notification object */
	const { notificationType, read } = data

	/**
	 * Handler to mark the notification as read
	 * (common to all notification types)
	 * */
	const handleMarkAsRead = () => {
		dispatch(markNotificationAsRead(data.id))
	}

	/** If the notification and content types do not exist or can't be found, return empty template */
	if (!templates[notificationType]) {
		return <></>
	}

	/**
	 * We want to retrieve our template, as well as the redirect target
	 * */
	const { target: handleRedirect, template: Template } = templates[notificationType]

	/**
	 * Complete click handler that will mark the notification
	 * as read, and also trigger the corresponding redirect */
	const onClick = () => {
		handleMarkAsRead()
		handleRedirect(data)
	}

	return (
		<MenuItem sx={styles.container} onClick={onClick}>
			<Flex sx={styles.contentWrapper}>
				{data.content && data.content != null && <Template content={data.content} />}
				{!read && <Box sx={styles.readNotice} />}
			</Flex>
		</MenuItem>
	)
}

export default FactoryNotification
