import { computeMimeType } from '../../../../../contexts/MediaItemProvider'

export const getTypeFromMimeType = (mimeType) => {
	/** Mime type */
	const type = computeMimeType(mimeType)

	let label

	switch (type) {
		case 'image':
			label = 'photo'
			break
		case 'textcard':
			label = 'text card'
			break
		case 'audio':
			label = 'audio message'
			break
		case 'video':
			label = 'video'
			break
		default:
			label = ''
			break
	}

	return label
}
