import { history } from '../../../../../../history'
import Submission from './templates/Submission'
import NewRSVP from './templates/NewRSVP'
import Funds from './templates/Funds'
import PreviewReady from './templates/PreviewReady'
import redirectToNextIfWindow from '../../../../../utils/redirectToNextIfWindow'
/**
 * Define notification templates available based on notification and content types
 */
let templates = {}

/** New submission of media to an event, by a contributor (EVENT_MEDIA) */
templates['SUBMISSION'] = {
	template: Submission,
	target: (props) => {
		if (props.content.isThankYouVideo) {
			history.push(`/event/${props.content.event.uuid}#play-thank-you`)
		} else {
			history.push(`/event/${props.content.event.uuid}/media/${props.content.id}`)
		}
	},
}

/** New RSVP to an event by a contributor (or future contributor) (RSVP) */
templates['NEW_RSVP'] = {
	template: NewRSVP,
	target: (props) => redirectToNextIfWindow(`/event/${props.content.event.uuid}/invitation/list`),
}

/** A preview job has been rendered and is ready for preview (RENDER_JOB) */
templates['PREVIEW_READY'] = {
	template: PreviewReady,
	target: (props) => redirectToNextIfWindow(`/event/${props.content.event.uuid}/preview`),
}

/** New RSVP to an event by a contributor (or future contributor) (RSVP) */
templates['FUNDS'] = {
	template: Funds,
	target: (props) => history.push(`/event/${props.content.event.uuid}/collect#${props.content.id}`),
}

export default templates
