import React, { createContext, useReducer, useContext, useState, useEffect } from 'react'

/** Build Current Event Context */
const TabContext = createContext()

/** Define Current Event Context Provider  */
const TabProvider = ({ active, children }) => {
	const [activeTab, setActiveTab] = useState(active)

	return <TabContext.Provider value={{ activeTab, setActiveTab }}>{children}</TabContext.Provider>
}

export default TabProvider

export const useTabContext = () => useContext(TabContext)
