import React from 'react'
import { useMultiStyleConfig } from '@chakra-ui/react'
import { getTypeFromMimeType } from '../utils'
import { HStack, Image, Box, Text, chakra } from '@chakra-ui/react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { icons } from '../config'

// adds additional dayjs APIs --> https://day.js.org/docs/en/plugin/relative-time
dayjs.extend(relativeTime)

/**
 * RSVP > NEW RSVP
 * New RSVP from a contributor to an event
 */
const NewRSVP = ({ content }) => {
	const { mimeType, createdAt, firstName, lastName } = content

	/** Retrieve styles */
	const styles = useMultiStyleConfig('Notification')

	/** Custom icon styles */
	const iconStyles = {
		...styles.icon,
		bg: 'rgba(75, 190, 255, 0.1)',
		borderColor: '#4BBEFF',
	}

	return (
		<HStack spacing="0.5rem">
			<Box sx={iconStyles}>
				<Image src={`/assets/images/icons/notifications/${icons['letter-heart']}.png`} boxSize="31px" />
			</Box>
			<Box>
				<Text lineHeight="1.125rem" fontSize="0.875rem">
					New RSVP:{' '}
					{firstName ? (
						<chakra.strong>
							{firstName} {lastName ? lastName : ''}
						</chakra.strong>
					) : (
						'Someone'
					)}{' '}
					plans on submitting
				</Text>
				<Text fontSize="0.75rem" color="#6C7A88">
					{dayjs(createdAt).fromNow()}
				</Text>
			</Box>
		</HStack>
	)
}

export default NewRSVP
